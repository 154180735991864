import React from 'react';
import styles from './special-messages-component.module.scss';
import locale from '../../locale/locale';

const SpecialMessagesComponent = ({ data }) => {
  // console.log(data);
  const {
    field_specialmessage_title,
    field_specialmessage_body,
    field_show_link,
    field_link_url,
    field_link_text,
    field_link_text_2,
    field_link_url_2,
    field_show_link_2,
    field_link_text_3,
    field_link_url_3,
    field_show_link_3,
    field_link_text_4,
    field_link_url_4,
    field_show_link_4,
    field_link_text_5,
    field_link_url_5,
    field_show_link_5
  } = data;
  //const messageBody = field_specialmessage_body.split('\r').join('<br>');
  const specialMessageBody = field_specialmessage_body.processed;//.split('\n').join('<br>');
  const bodyHeading2 = specialMessageBody.split('<h2 class="heading2">').join('<h2 style="font-size: 1.5em;margin-top: 0.83em;margin-bottom: 0.83em;margin-left: 0;margin-right: 0;font-weight: bold;">');
  const bodyremoveExtrabreak = bodyHeading2.split('<br><br>').join('<br>');
  const bodylinkstyled = bodyremoveExtrabreak.split('<a ').join('<a style="color:blue;"target="_blank"');
  const bodyHeading1 = bodylinkstyled.split('<h1 class="heading1">').join('<h1 style="font-size: 2em;margin-top: 0.67em;margin-bottom: 0.67em;margin-left: 0;margin-right: 0;font-weight: bold;">');
  const messageBody = bodyHeading1.split('<h3 class="heading3">').join('<h3 style="font-size: 1.17em;margin-top: 1em;margin-bottom: 1em;margin-left: 0;margin-right: 0;font-weight: bold;">');

  const specialMessageTitle = field_specialmessage_title.processed;//.split('\n').join('<br>');
  const titleHeading2 = specialMessageTitle.split('<h2 class="heading2">').join('<h2 style="font-size: 1.5em;margin-top: 0.83em;margin-bottom: 0.83em;margin-left: 0;margin-right: 0;font-weight: bold;">');
  const titleremoveExtrabreak = titleHeading2.split('<br><br>').join('<br>');
  const titlelinkstyled = titleremoveExtrabreak.split('<a ').join('<a style="color:blue;"target="_blank"');
  const titleHeading1 = titlelinkstyled.split('<h1 class="heading1">').join('<h1 style="font-size: 2em;margin-top: 0.67em;margin-bottom: 0.67em;margin-left: 0;margin-right: 0;font-weight: bold;">');
  const messageTitle = titleHeading1.split('<h3 class="heading3">').join('<h3 style="font-size: 1.17em;margin-top: 1em;margin-bottom: 1em;margin-left: 0;margin-right: 0;font-weight: bold;">');

  return (
    <div className={styles.specialMessagesComponentContainer}>
      <div className={styles.specialMessagesComponentBody}>
        {field_show_link && field_link_url !== '' && field_link_url.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url} target="_blank" rel="noreferrer noopener">
              {field_link_text}
            </a>
          </div>
        )}
        {field_show_link_2 && field_link_url_2 !== '' && field_link_url_2.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_2} target="_blank" rel="noreferrer noopener">
              {field_link_text_2}
            </a>
          </div>
        )}
        {field_show_link_3 && field_link_url_3 !== '' && field_link_url_3.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_3} target="_blank" rel="noreferrer noopener">
              {field_link_text_3}
            </a>
          </div>
        )}
        {field_show_link_4 && field_link_url_4 !== '' && field_link_url_4.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_4} target="_blank" rel="noreferrer noopener">
              {field_link_text_4}
            </a>
          </div>
        )}
        {field_show_link_5 && field_link_url_5 !== '' && field_link_url_5.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_5} target="_blank" rel="noreferrer noopener">
              {field_link_text_5}
            </a>
          </div>
        )}
        <div
          className={styles.specialMessagesComponentTitle}
          dangerouslySetInnerHTML={{ __html: messageTitle }}
        ></div>
        <div
          className={styles.specialMessagesComponentContent}
          dangerouslySetInnerHTML={{ __html: messageBody }}
        ></div>
       
        {field_show_link && field_link_url !== '' && field_link_url.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url} target="_blank" rel="noreferrer noopener">
              {field_link_text}
            </a>
          </div>
        )}
        {field_show_link_2 && field_link_url_2 !== '' && field_link_url_2.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_2} target="_blank" rel="noreferrer noopener">
              {field_link_text_2}
            </a>
          </div>
        )}
        {field_show_link_3 && field_link_url_3 !== '' && field_link_url_3.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_3} target="_blank" rel="noreferrer noopener">
              {field_link_text_3}
            </a>
          </div>
        )}
        {field_show_link_4 && field_link_url_4 !== '' && field_link_url_4.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_4} target="_blank" rel="noreferrer noopener">
              {field_link_text_4}
            </a>
          </div>
        )}
        {field_show_link_5 && field_link_url_5 !== '' && field_link_url_5.indexOf('http') > -1 && (
          <div className={styles.specialMessagesComponentLink}>
            <a href={field_link_url_5} target="_blank" rel="noreferrer noopener">
              {field_link_text_5}
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default SpecialMessagesComponent;
