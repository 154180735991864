// Libs.
import React from 'react';
// Deps.
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
import { graphql } from 'gatsby';
import { formatFieldsValue } from '../libs/formatFromGraph';
import SpecialMessagesComponent from '../components/special-messages/special-message-component';

import navigate from '../libs/navigate';

const SpecialMessagesPage = ({ data }) => {
  const pageData = formatFieldsValue(data);
  const { specialMessage: { field_allowaccess }} = pageData
  if (!field_allowaccess) {
    navigate('/');
    return (<></>);
  }

  return (
    <Layout>
      <SEO title="Special Message" />
      <SpecialMessagesComponent data={pageData.specialMessage} />
    </Layout>
  );
};

export default SpecialMessagesPage;

export const query = graphql`
         query {
           specialMessage: configPagesSpecialMessages {
             id
             field_link_text
             field_link_url
             field_show_link
             field_link_text_2
             field_link_url_2
             field_show_link_2
             field_link_text_3
             field_link_url_3
             field_show_link_3
             field_link_text_4
             field_link_url_4
             field_show_link_4
             field_link_text_5
             field_link_url_5
             field_show_link_5
             field_allowaccess
             field_specialmessage_title{
              processed
             }
             field_specialmessage_body {
              processed
             }
           }
         }
       `;
